import React from "react"

const icon_style = {
  height: "1em",
  width: "auto",
  display: "inline"
}

/* Most icons are obtained from Bootstrap Icons */

export const LeftSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm10.5 10V4a.5.5 0 0 0-.832-.374l-4.5 4a.5.5 0 0 0 0 .748l4.5 4A.5.5 0 0 0 10.5 12z"/>
  </svg>
)

export const RightSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.5 10a.5.5 0 0 0 .832.374l4.5-4a.5.5 0 0 0 0-.748l-4.5-4A.5.5 0 0 0 5.5 4v8z"/>
  </svg>
)

export const CaretDownSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
  </svg>
)

export const ChevronDownSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
  </svg>
)

export const CTFtimeSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <polygon points="0 0 16 0 16 16 0 16 5.85 8 0 0" />
  </svg>
)

export const DiscordSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
  </svg>
)

export const GitHubSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
  </svg>
)

export const InstagramSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
  </svg>
)

export const MastodonSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path d="M11.19 12.195c2.016-.24 3.77-1.475 3.99-2.603.348-1.778.32-4.339.32-4.339 0-3.47-2.286-4.488-2.286-4.488C12.062.238 10.083.017 8.027 0h-.05C5.92.017 3.942.238 2.79.765c0 0-2.285 1.017-2.285 4.488l-.002.662c-.004.64-.007 1.35.011 2.091.083 3.394.626 6.74 3.78 7.57 1.454.383 2.703.463 3.709.408 1.823-.1 2.847-.647 2.847-.647l-.06-1.317s-1.303.41-2.767.36c-1.45-.05-2.98-.156-3.215-1.928a3.614 3.614 0 0 1-.033-.496s1.424.346 3.228.428c1.103.05 2.137-.064 3.188-.189zm1.613-2.47H11.13v-4.08c0-.859-.364-1.295-1.091-1.295-.804 0-1.207.517-1.207 1.541v2.233H7.168V5.89c0-1.024-.403-1.541-1.207-1.541-.727 0-1.091.436-1.091 1.296v4.079H3.197V5.522c0-.859.22-1.541.66-2.046.456-.505 1.052-.764 1.793-.764.856 0 1.504.328 1.933.983L8 4.39l.417-.695c.429-.655 1.077-.983 1.934-.983.74 0 1.336.259 1.791.764.442.505.661 1.187.661 2.046v4.203z"/>
  </svg>
)

export const RedditSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path d="M6.167 8a.831.831 0 0 0-.83.83c0 .459.372.84.83.831a.831.831 0 0 0 0-1.661zm1.843 3.647c.315 0 1.403-.038 1.976-.611a.232.232 0 0 0 0-.306.213.213 0 0 0-.306 0c-.353.363-1.126.487-1.67.487-.545 0-1.308-.124-1.671-.487a.213.213 0 0 0-.306 0 .213.213 0 0 0 0 .306c.564.563 1.652.61 1.977.61zm.992-2.807c0 .458.373.83.831.83.458 0 .83-.381.83-.83a.831.831 0 0 0-1.66 0z"/>
    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.828-1.165c-.315 0-.602.124-.812.325-.801-.573-1.9-.945-3.121-.993l.534-2.501 1.738.372a.83.83 0 1 0 .83-.869.83.83 0 0 0-.744.468l-1.938-.41a.203.203 0 0 0-.153.028.186.186 0 0 0-.086.134l-.592 2.788c-1.24.038-2.358.41-3.17.992-.21-.2-.496-.324-.81-.324a1.163 1.163 0 0 0-.478 2.224c-.02.115-.029.23-.029.353 0 1.795 2.091 3.256 4.669 3.256 2.577 0 4.668-1.451 4.668-3.256 0-.114-.01-.238-.029-.353.401-.181.688-.592.688-1.069 0-.65-.525-1.165-1.165-1.165z"/>
  </svg>
)

export const TwitterSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
  </svg>
)

export const LinkedInSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
  </svg>
)

export const YouTubeSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
  </svg>
)

export const MailSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
  </svg>
)

export const GlobeSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z"/>
  </svg>
)

export const LocationSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
  </svg>
)

export const PdfSvg = (style: React.CSSProperties) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{...icon_style, ...style}}>
    <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
    <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
  </svg>
)


/**
 * To update the Pwny icon, ensure that Adobe Illustrator's export options are set to:
 * - Styling: Internal CSS
 * - Font: SVG
 * - Images: Preserve
 * - Object IDs: Layer Names
 * - Decimal: 2
 * - Minify: Yes
 * - Responsive: Yes
 * 
 * Rename "class" to "className" in the SVG code.
 * Turn the contents of the style tag into a string and wrap it in curly braces.
 * - <style>.cls-1{fill:#3c5;}</style>
 * - <style>{".cls-1{fill:#3c5;}"}</style>
 * Add height and width props to the SVG tag.
 */
export const PwnySvg = (style: React.CSSProperties) => (
    <svg id="Pwny" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440 440" style={{...icon_style, ...style}}><defs><style>{".cls-1{stroke:var(--color-primary);stroke-linejoin:round;stroke-width:4px;}.cls-1,.cls-2{fill:var(--color-primary);}"}</style></defs><path fill="#000000" opacity="1.000000" stroke="none" 
    d="
  M236.000000,501.000000 
    C158.666687,501.000000 81.833321,500.970825 5.000212,501.096191 
    C1.575908,501.101776 0.903036,500.423798 0.905301,497.000000 
    C1.013779,333.000092 1.013772,169.000122 0.905334,5.000237 
    C0.903070,1.576914 1.575824,0.903022 4.999887,0.905288 
    C168.999771,1.013774 332.999725,1.013761 496.999634,0.905352 
    C500.422668,0.903090 501.096985,1.575447 501.094727,4.999767 
    C500.986206,168.999649 500.986237,332.999603 501.094604,496.999512 
    C501.096893,500.422272 500.425415,501.100739 497.000305,501.095917 
    C410.166992,500.974121 323.333466,501.000000 236.000000,501.000000 
  z"/>
  <path fill="#67C357" opacity="1.000000" stroke="none" 
    d="
  M159.961212,218.505310 
    C175.627808,226.673187 191.120560,234.506943 206.829102,242.611038 
    C209.651337,248.476395 207.214111,254.019379 206.729416,259.547729 
    C206.412292,263.164673 205.590652,266.763336 204.965591,270.365082 
    C203.825348,276.935303 201.241348,278.439270 195.311508,275.526031 
    C183.512878,269.729584 171.963181,263.440430 160.318954,257.342438 
    C155.754761,254.952209 151.082138,252.747208 146.296707,249.925781 
    C145.303543,249.292236 144.665558,248.835663 143.665527,248.221497 
    C142.962906,247.659256 142.616852,247.284836 142.119446,246.556519 
    C140.229507,241.637772 137.961945,237.209000 138.010300,232.085388 
    C138.360092,234.550369 138.977249,236.956833 135.426147,237.467300 
    C130.720276,238.143768 127.214172,236.260101 126.733521,232.886627 
    C126.542236,231.544037 126.796127,230.208206 127.044250,228.918854 
    C127.602951,226.015549 126.889748,223.767120 124.466087,221.918869 
    C121.763977,219.858246 120.450333,217.207748 121.348694,213.612595 
    C122.033379,210.872543 120.185310,208.071655 121.343536,204.936615 
    C122.891838,203.173431 123.258347,201.092026 124.360138,199.566833 
    C126.100166,197.158096 123.568687,197.877045 122.671494,197.066040 
    C122.152092,196.844254 121.947594,196.750076 121.426208,196.536942 
    C118.755783,195.769333 117.073708,194.344803 115.969482,191.822266 
    C115.793587,191.386093 115.757652,191.218521 115.628311,190.761292 
    C115.477737,190.769577 115.335388,191.019867 114.887932,191.373138 
    C113.717499,191.903503 112.828804,191.603729 111.476929,191.240936 
    C110.690781,191.294678 110.387894,191.424408 109.850571,191.726440 
    C108.368034,192.306961 107.136635,192.018250 105.605141,191.735046 
    C104.066025,190.797775 103.918396,189.542557 103.890892,187.948090 
    C103.795776,187.496719 103.735718,187.321365 103.522743,186.903244 
    C100.347809,183.710800 102.227463,181.545303 104.747620,179.193924 
    C118.770691,174.858307 132.963837,172.456329 146.951721,169.177856 
    C165.941864,164.726974 185.038452,160.728882 204.099426,156.582733 
    C208.419724,155.642990 212.781250,154.892883 217.539551,154.108704 
    C220.628830,158.345184 219.280304,162.917725 218.950104,167.278168 
    C218.524902,172.893005 217.843124,178.505798 216.988708,184.086624 
    C216.405396,187.896561 214.011063,190.048950 210.486374,190.766617 
    C192.446320,194.439758 174.483551,198.480515 156.346512,201.708359 
    C151.211349,202.622253 145.826797,202.768311 140.909302,204.970413 
    C139.902222,205.421402 138.925171,205.437134 140.106155,207.031189 
    C145.330200,209.436523 150.243408,211.661743 155.000656,214.210098 
    C156.856140,215.204041 158.929062,215.961197 159.961212,218.505310 
  z"/>
  <path fill="#67C357" opacity="1.000000" stroke="none" 
    d="
  M412.479797,323.991272 
    C413.409546,334.961853 413.375549,334.802429 403.536774,336.901001 
    C385.910858,340.660492 368.333527,344.647247 350.730072,348.512604 
    C328.806366,353.326569 306.876831,358.113800 284.547241,362.875793 
    C282.478973,359.844818 282.190674,356.751770 282.628326,353.309326 
    C283.461823,346.753357 284.021820,340.158752 284.741730,333.584106 
    C285.220490,329.211761 287.474213,326.932983 291.439362,326.132507 
    C307.824371,322.824554 324.093658,318.952423 340.555542,316.026367 
    C343.321106,315.534790 345.933563,314.328156 349.205505,314.366394 
    C349.883545,314.401184 350.150940,314.401794 350.810486,314.381836 
    C353.605103,313.828217 356.147705,313.738068 358.533081,311.958191 
    C359.965790,311.269867 361.144287,311.097229 362.657257,311.106506 
    C363.209900,311.000916 363.561584,311.588715 363.540955,310.895325 
    C355.933472,306.754150 347.887726,303.860626 341.235168,297.947815 
    C328.369751,291.174957 315.630127,284.754547 302.920441,278.275330 
    C300.450592,277.016205 298.097565,275.528015 295.375732,273.991760 
    C293.770691,271.752869 293.684235,269.457703 293.896088,267.113739 
    C294.570801,259.649353 296.007935,252.295471 297.436371,244.960083 
    C298.411713,239.951447 301.469055,238.416565 305.906982,240.759415 
    C323.131073,249.852310 340.624969,258.433502 357.617523,267.964874 
    C358.643127,268.540161 359.522095,269.306335 360.447754,270.558411 
    C360.399567,274.052185 363.122192,276.278046 363.362061,279.647339 
    C362.442596,286.711243 364.261078,292.286163 369.923462,296.429749 
    C371.229645,297.385590 372.521454,298.952759 372.437012,300.549316 
    C372.276642,303.580841 374.451691,304.783051 376.419739,306.661133 
    C377.447571,308.409729 379.383026,308.655823 380.353638,310.435486 
    C380.524506,310.991394 380.551361,311.224976 380.501953,311.798828 
    C380.303528,312.331848 380.181305,312.524658 379.810730,312.943054 
    C377.081329,314.260254 378.095398,315.629089 379.426392,317.262390 
    C379.948364,318.181976 380.081116,318.948608 380.727356,319.752411 
    C381.091339,320.026154 381.254486,320.121307 381.693054,320.331329 
    C386.003967,320.995605 388.598602,325.494629 393.534241,324.537170 
    C394.118225,324.544128 394.351624,324.551514 394.933929,324.593262 
    C395.609528,324.689270 396.128876,324.741486 396.180817,324.949249 
    C397.404541,329.844482 402.176727,327.934906 405.098053,329.561829 
    C406.592194,330.393860 407.830841,329.448730 408.796814,328.057068 
    C409.800659,326.610809 410.222137,324.653534 412.479797,323.991272 
  z"/>
  <path fill="#652E8F" opacity="1.000000" stroke="none" 
    d="
  M140.228851,208.661484 
    C137.686539,207.743790 135.376373,206.942383 132.853638,204.630402 
    C143.806824,202.327682 153.937241,200.163574 164.082809,198.073029 
    C179.231812,194.951523 194.370468,191.771835 209.560898,188.864517 
    C213.543793,188.102219 214.901779,186.392242 215.153259,182.299530 
    C215.713608,173.180603 216.976578,164.104858 217.954254,154.586700 
    C222.263580,158.711456 225.030228,164.314774 228.109299,169.643799 
    C230.884857,174.447510 227.644363,179.613663 227.753143,184.680023 
    C227.871750,190.204453 226.390594,195.745071 226.111572,201.303970 
    C225.973709,204.050522 225.057983,204.828491 222.517776,205.332016 
    C206.947876,208.418198 191.417770,211.705063 175.462982,214.908798 
    C174.241898,213.930618 174.802902,213.054367 175.144714,211.840271 
    C174.938370,212.843613 173.532181,212.667404 173.319916,212.291107 
    C170.716431,207.675598 164.749619,210.119354 161.566483,206.561951 
    C160.395416,205.253189 158.228134,206.362686 156.871338,206.880020 
    C152.772430,208.442963 148.771469,209.119843 144.441437,208.014069 
    C143.264954,207.713638 141.895493,208.549515 140.228851,208.661484 
  z"/>
  <path fill="#652E8F" opacity="1.000000" stroke="none" 
    d="
  M351.249969,315.567139 
    C331.903717,319.881226 312.809723,324.073029 293.640289,327.885834 
    C289.113739,328.786194 286.910492,330.099060 286.729492,335.297913 
    C286.419098,344.211731 285.026398,353.087860 284.116425,362.409424 
    C279.581726,358.082825 276.513519,352.217194 273.540833,346.537781 
    C271.255341,342.171295 274.240662,336.879425 274.254364,331.917664 
    C274.268280,326.872253 275.846802,321.829132 275.838928,316.786377 
    C275.832916,312.937286 277.288940,311.643463 280.696106,310.968079 
    C293.530884,308.423828 306.330048,305.700134 319.546234,303.089783 
    C320.386963,303.351196 320.499237,303.737549 320.500885,304.182159 
    C320.539886,303.953979 320.528992,303.721405 320.805328,303.312622 
    C321.048767,303.132568 321.512085,303.005951 321.747101,302.956604 
    C322.828400,302.994598 323.682159,303.042175 324.762695,303.484253 
    C325.238708,303.633331 325.433960,303.675323 325.934082,303.666687 
    C327.223724,303.449280 328.764832,303.055634 329.041718,303.889343 
    C330.977203,309.717987 338.028595,306.394073 340.907745,310.670013 
    C341.561310,311.640656 343.331024,310.187531 344.586639,309.848969 
    C349.023987,308.652618 351.081238,310.282196 351.249969,315.567139 
  z"/>
  <path fill="#62A856" opacity="1.000000" stroke="none" 
    d="
  M121.704895,204.794998 
    C123.150055,207.162476 121.541992,210.444870 124.873604,211.800720 
    C125.737213,212.152161 125.550362,213.264908 124.607437,213.749832 
    C120.397110,215.915009 122.869499,218.516174 124.867165,219.671585 
    C129.472870,222.335358 130.358673,225.788727 128.918274,230.520767 
    C128.003159,233.527039 127.399353,236.718323 132.489883,234.669327 
    C134.270416,233.952652 134.914337,236.313538 136.569550,235.934830 
    C137.693039,233.301025 135.133453,232.186234 134.264343,230.384125 
    C133.786560,229.393402 132.788208,228.214569 134.250931,227.367065 
    C135.363815,226.722260 136.806915,227.128540 137.299911,228.243286 
    C138.626312,231.242676 141.991196,233.606445 140.397980,237.587494 
    C139.954391,238.695953 140.423782,239.681961 141.414322,240.081360 
    C144.012680,241.129074 143.480545,243.080292 143.051987,245.393005 
    C142.628403,246.458618 141.949432,246.574951 140.851212,246.504868 
    C137.586624,246.154327 135.402115,244.299942 132.726288,242.700836 
    C131.895981,242.086243 131.328125,241.698181 130.362152,241.306931 
    C123.965187,239.039047 118.555122,235.555756 112.924858,232.549011 
    C108.725739,230.306519 104.457764,228.190582 100.003212,225.672623 
    C98.719017,224.815781 97.704437,224.202759 96.314545,223.526184 
    C92.054527,222.426239 89.273239,219.697189 86.251587,216.806793 
    C84.641068,215.387238 85.022385,213.912994 85.236504,212.442642 
    C86.189148,205.900864 87.116028,199.355331 88.132477,192.382080 
    C90.291519,188.248520 96.034515,188.350601 98.823883,192.141571 
    C101.226143,195.406464 102.814423,195.519547 106.417221,193.587784 
    C108.602600,192.416031 111.170448,191.981934 113.372940,193.997925 
    C116.330330,196.704895 121.616173,197.780884 120.096153,203.623810 
    C119.630249,205.414749 118.264870,206.287155 116.519226,207.067520 
    C118.524620,206.633102 119.388702,204.485611 121.704895,204.794998 
  z"/>
  <path fill="#652E8F" opacity="1.000000" stroke="none" 
    d="
  M360.309906,272.138916 
    C341.263916,261.902466 322.427277,251.960068 303.628784,241.946045 
    C301.110687,240.604660 300.145782,241.180115 299.726044,243.949921 
    C298.255615,253.653091 296.632324,263.333099 295.063965,273.429993 
    C291.047211,269.086121 288.236450,263.534363 284.777344,258.414490 
    C283.838654,257.025085 283.868866,255.561523 284.113800,254.109711 
    C285.694275,244.741470 287.565033,235.415344 288.856628,226.008957 
    C289.305786,222.738052 290.303955,223.015320 292.470306,224.166931 
    C299.888550,228.110397 307.331940,232.006546 314.759186,235.933136 
    C325.965973,241.857834 337.183716,247.762131 348.363861,253.736664 
    C354.015503,256.756836 359.594940,259.912079 365.559113,263.056580 
    C364.894379,266.148041 363.851562,269.179169 362.185638,272.271851 
    C361.436035,272.577881 360.981415,272.553680 360.309906,272.138916 
  z"/>
  <path fill="#652E8F" opacity="1.000000" stroke="none" 
    d="
  M140.509003,245.263214 
    C141.309525,245.213593 141.880951,245.400833 142.746140,245.660522 
    C143.356293,245.970856 143.672684,246.208755 144.170410,246.681580 
    C144.544312,247.315979 144.853561,247.577316 145.595566,247.635254 
    C162.735535,256.286652 179.560730,265.000916 196.382965,273.720886 
    C201.739151,276.497314 201.790161,276.513153 202.717636,270.803040 
    C204.182205,261.786163 205.565079,252.756027 207.013733,243.306442 
    C210.306107,247.793152 213.594482,252.687210 216.812561,257.627045 
    C217.828873,259.187103 218.254364,260.939911 217.924240,262.842041 
    C216.160980,273.001312 214.417191,283.163940 212.555603,293.970184 
    C187.134659,280.628113 161.916122,267.392242 136.373169,254.018204 
    C134.494003,249.589569 138.632462,248.173691 140.509003,245.263214 
  z"/>
  <path fill="#62A856" opacity="1.000000" stroke="none" 
    d="
  M359.920746,272.153290 
    C360.553833,271.935822 361.019073,272.004303 361.831787,272.134857 
    C364.680481,271.211517 366.742218,272.542877 368.781982,273.580658 
    C382.422302,280.520508 395.946106,287.684052 409.449768,294.885803 
    C411.830017,296.155182 413.575500,298.217773 416.065063,299.931244 
    C416.623413,307.448608 415.522369,314.613525 413.386169,321.913696 
    C411.736694,322.178192 410.456512,321.930939 409.284058,321.329620 
    C407.151428,320.235840 405.149384,319.595001 403.052490,321.550964 
    C401.490295,323.008179 400.066284,322.101776 398.664917,320.625122 
    C398.488678,320.439026 398.108917,320.196686 397.892395,320.214600 
    C396.826935,320.736206 396.635742,321.830078 395.480652,322.443970 
    C394.903320,322.944275 394.241638,321.712952 393.994324,323.100403 
    C389.694519,324.707306 387.797791,323.451782 386.197235,318.146790 
    C385.681885,316.438568 385.350494,314.619629 385.153961,312.886292 
    C384.735626,309.196289 382.980682,309.721954 380.345093,311.003662 
    C378.766785,310.152985 376.903900,310.294922 376.079895,308.317444 
    C375.316559,304.323822 378.339752,300.005585 374.064972,296.931702 
    C373.686493,296.659576 373.299591,295.907135 373.481110,295.618713 
    C375.884796,291.799744 371.165497,289.464691 371.551666,286.093414 
    C371.727661,284.556946 370.850433,284.110870 369.479248,283.978851 
    C366.718201,283.713043 364.578827,282.048706 362.174866,280.302185 
    C362.088318,278.342621 361.378693,277.180359 359.895966,276.423401 
    C357.503174,275.201843 358.837799,273.803345 359.920746,272.153290 
  z"/>
  <path fill="#4F2656" opacity="1.000000" stroke="none" 
    d="
  M416.187866,299.800598 
    C409.997559,298.206055 404.486511,294.484344 398.640015,291.465973 
    C386.600983,285.250610 374.620819,278.921326 362.397827,272.417908 
    C362.180756,268.910065 359.638763,264.659637 365.678894,263.339783 
    C376.605621,268.560150 387.269562,274.074310 398.004120,279.447388 
    C406.662018,283.781006 411.605652,291.360138 416.187866,299.800598 
  z"/>
  <path fill="#4F2656" opacity="1.000000" stroke="none" 
    d="
  M140.419678,244.952606 
    C140.708466,248.668961 139.246246,251.494629 136.031265,253.595398 
    C123.198235,247.171707 110.101875,240.883469 97.605515,233.570007 
    C91.744995,230.140167 89.712990,222.894501 86.130768,216.857391 
    C89.593658,218.228012 93.059685,219.989929 96.834625,221.936203 
    C97.527588,223.112579 98.170952,223.780853 99.586357,223.774933 
    C109.144218,228.012360 118.236465,232.667679 126.915268,238.069244 
    C128.101440,238.807541 129.437241,238.745041 130.757996,239.767227 
    C131.357681,240.463791 131.769684,240.817764 132.616287,240.970871 
    C135.595032,241.733063 137.739273,243.507889 140.419678,244.952606 
  z"/>
  <path fill="#64B556" opacity="1.000000" stroke="none" 
    d="
  M122.368202,204.995697 
    C120.282860,207.274094 116.194443,207.649124 116.696449,211.840591 
    C116.131447,209.454773 114.137306,207.326843 117.601189,205.327560 
    C119.728661,204.099594 118.425835,201.338257 118.022171,199.385117 
    C117.544022,197.071625 115.620613,197.724442 113.966377,198.135986 
    C112.963615,198.385452 110.852036,199.012299 111.481407,197.318085 
    C113.860672,190.913269 109.090378,195.368591 107.864082,194.959534 
    C106.789673,194.601105 106.593925,195.421356 107.381165,198.411591 
    C107.449883,198.672638 107.214645,199.013702 107.098015,199.390564 
    C104.236015,198.213623 101.822105,193.773621 98.136574,198.296661 
    C97.575226,198.985565 96.189842,197.640396 96.739349,196.580322 
    C99.197128,191.838898 97.742043,190.881577 92.960251,192.077225 
    C91.798576,192.367691 90.451477,188.714417 88.475456,191.885056 
    C88.509384,189.225296 88.889603,186.503204 89.072769,183.767914 
    C89.190994,182.002319 90.605003,181.565262 91.717079,181.316223 
    C95.823051,180.396790 99.989120,179.745773 104.551971,178.967606 
    C104.822899,180.243698 106.526253,181.668015 104.504822,182.869736 
    C101.141899,184.868942 104.899063,184.905182 105.705276,185.926865 
    C105.994957,187.520569 104.607407,188.899429 105.784714,190.754089 
    C107.169853,190.907562 108.247177,191.554947 109.704514,191.229370 
    C109.987373,190.580078 109.987473,190.132019 109.999680,189.335541 
    C111.820160,188.744781 112.928085,190.159088 115.170258,190.909073 
    C118.687370,190.916611 119.092117,194.718094 121.942398,194.742310 
    C125.116638,195.761765 127.936363,197.450912 131.184509,197.501144 
    C131.086975,199.250305 129.976822,199.708710 129.100906,199.530670 
    C126.347099,198.970917 124.992584,199.265350 125.758728,202.657455 
    C126.209412,204.652817 124.343781,204.944138 122.368202,204.995697 
  z"/>
  <path fill="#4F2656" opacity="1.000000" stroke="none" 
    d="
  M351.276062,315.833374 
    C348.744843,310.279144 348.231384,310.044250 343.432037,312.650635 
    C341.749115,313.564545 340.157776,314.405487 338.635895,312.454193 
    C336.454712,309.657654 333.979187,308.088440 330.365601,310.098419 
    C329.755615,310.437744 328.665894,310.198792 329.066895,308.859070 
    C329.509369,307.380829 329.192535,306.012726 328.403687,304.728821 
    C326.872955,304.489746 327.603760,308.166107 325.158752,306.201874 
    C325.217407,305.796661 325.359467,305.556732 325.410767,305.273499 
    C325.461700,304.991974 325.446411,305.087250 325.194672,305.792175 
    C324.123749,305.162384 323.222809,304.299744 322.151978,303.172180 
    C325.404968,301.965912 328.827820,301.024567 332.640656,300.091003 
    C333.435425,300.518433 333.909241,300.687225 334.698669,300.337097 
    C336.850311,299.549408 338.784821,298.922607 340.909149,297.955872 
    C349.342773,300.712402 356.789032,305.423065 364.919250,309.492371 
    C365.704437,310.828918 365.267700,311.688507 364.199158,312.721039 
    C362.609802,313.016449 361.300934,313.049927 359.598022,313.102753 
    C357.414368,315.195648 354.080933,312.428864 352.267395,315.468506 
    C351.845459,315.737122 351.686157,315.787964 351.276062,315.833374 
  z"/>
  <path fill="#64B556" opacity="1.000000" stroke="none" 
    d="
  M398.729492,318.119354 
    C400.101990,320.090942 401.002380,323.316650 403.039154,318.592010 
    C403.970032,316.432678 405.354889,318.252563 406.401794,318.382080 
    C408.832275,318.682831 411.211945,319.396179 412.963928,321.801880 
    C413.027588,322.564117 412.992554,322.990967 412.891174,323.732086 
    C412.375427,325.267639 411.988373,326.516327 411.466003,327.705566 
    C408.957001,333.417114 408.141541,333.694244 402.375580,330.564819 
    C401.473297,330.075104 400.601440,329.124817 400.182373,330.678345 
    C399.406006,333.556335 397.161774,332.648987 395.871338,332.029083 
    C394.247803,331.249176 395.816101,329.765808 396.181274,328.635040 
    C396.505615,327.630493 396.799042,326.741150 395.285950,326.071899 
    C394.613129,325.693817 394.506958,325.285034 394.499481,324.834351 
    C394.457031,325.067566 394.469910,325.305817 394.222473,325.764587 
    C389.774139,329.469666 387.448303,324.372101 384.240448,323.483307 
    C383.112152,323.170715 381.616852,323.015808 380.220825,322.192566 
    C376.279083,321.993652 378.110718,320.117249 378.977997,318.000885 
    C379.294250,316.831818 379.583435,316.295959 379.616577,315.378510 
    C379.537445,314.227478 379.358521,313.381409 379.822083,312.270935 
    C380.000000,311.997559 379.998505,311.510437 379.997833,311.267151 
    C380.937531,308.191895 383.843140,307.334106 385.260986,304.520020 
    C387.421143,308.247711 384.784119,312.693451 389.005096,314.670105 
    C384.272156,317.856720 393.192047,319.144775 390.175751,322.349609 
    C389.992279,322.544556 392.304169,322.894470 393.751404,322.212830 
    C397.432098,321.066345 393.028320,319.118042 394.933716,317.658783 
    C395.887085,317.170502 397.036041,319.400146 398.729492,318.119354 
  z"/>
  <path fill="#4F2656" opacity="1.000000" stroke="none" 
    d="
  M140.200012,208.922394 
    C140.372498,206.113327 144.028000,206.882126 144.606979,204.294006 
    C144.705215,203.854889 145.284225,203.930832 145.803665,204.321548 
    C150.043396,207.510422 151.031204,207.512619 155.063675,204.555618 
    C156.903870,203.206253 162.380066,205.263809 164.377167,206.115005 
    C166.762833,207.131866 169.665161,209.307617 172.637253,206.497070 
    C173.155167,208.461853 172.239838,210.186340 173.573868,211.444641 
    C175.769455,208.848541 175.887054,208.814468 176.997559,210.720657 
    C176.433731,212.088455 175.856323,213.192551 175.165253,214.594574 
    C170.253433,216.036514 165.455276,217.180527 160.396103,218.581985 
    C153.594116,215.600449 147.053146,212.361481 140.200012,208.922394 
  z"/>
  <path fill="#5271FF" opacity="1.000000" stroke="none" 
    d="
  M227.175476,349.635071 
    C232.764893,351.543243 234.870956,355.241516 233.986771,361.321747 
    C233.418167,365.232025 229.807205,367.969116 225.176636,367.999908 
    C220.056747,368.033936 215.812073,364.945587 215.023163,360.612366 
    C214.077240,355.416687 216.341675,351.780792 221.206223,349.857819 
    C223.160309,349.085327 224.909882,348.740082 227.175476,349.635071 
  z"/>
  <path fill="#F09114" opacity="1.000000" stroke="none" 
    d="
  M277.113617,113.024330 
    C276.065826,112.825516 275.442352,112.639557 274.825043,112.434959 
    C272.878998,111.789970 269.223846,111.982231 269.388397,110.253761 
    C269.674011,107.253685 269.447296,103.631493 272.249390,101.203133 
    C274.128326,99.574799 287.120850,100.728867 288.321991,102.873398 
    C290.493500,106.750542 287.963135,110.537781 287.697327,114.368996 
    C287.626953,115.383293 286.140411,114.904404 285.158752,114.657753 
    C282.641846,114.025375 280.082886,113.560501 277.113617,113.024330 
  z"/>
  <path fill="#FF66C4" opacity="1.000000" stroke="none" 
    d="
  M251.444046,264.202698 
    C248.477219,265.377625 250.009171,266.246948 251.317841,266.696442 
    C252.845978,267.221283 254.916489,267.089203 253.704071,269.641296 
    C252.939575,271.250549 252.427780,272.586761 249.742661,271.506378 
    C246.199844,270.080872 242.233627,269.457611 238.392746,269.102356 
    C235.345276,268.820435 234.801102,267.328552 235.005981,264.859192 
    C235.564133,258.132050 239.270386,255.259079 244.527084,256.880951 
    C246.820450,257.588562 246.774231,259.236328 247.391006,260.742340 
    C250.126648,259.427124 252.735382,258.172882 255.569733,256.810211 
    C256.675293,260.734497 255.211380,262.906952 251.444046,264.202698 
  z"/>
  <path fill="#F09114" opacity="1.000000" stroke="none" 
    d="
  M222.286072,329.957214 
    C221.311493,322.005432 225.001480,317.387360 231.348221,316.910583 
    C240.320328,316.236633 242.879150,324.299652 239.993164,331.557037 
    C238.894455,334.319977 236.502762,333.055237 234.935822,332.816895 
    C230.809448,332.189392 226.772675,330.972839 222.286072,329.957214 
  z"/>
  <path fill="#008037" opacity="1.000000" stroke="none" 
    d="
  M276.883331,139.102539 
    C274.745789,135.271011 270.863220,136.068588 267.747253,135.107773 
    C265.934631,134.548828 263.902710,134.627014 264.182404,132.072098 
    C264.507446,129.103012 266.861908,129.791489 268.700867,130.047806 
    C271.131012,130.386536 273.562134,130.846252 275.928009,131.491638 
    C281.399261,132.984100 283.138916,135.964890 281.928345,141.442551 
    C281.113800,145.128281 277.418518,147.008881 272.650940,146.070023 
    C269.921387,145.532516 267.226471,144.821381 264.502350,144.253647 
    C262.827209,143.904526 261.670837,143.188217 262.148315,141.259201 
    C262.660431,139.190002 263.969421,139.042786 265.939667,139.399170 
    C269.143585,139.978714 272.239197,141.465179 275.623322,140.837250 
    C276.361969,140.700195 276.724182,140.200241 276.883331,139.102539 
  z"/>
  <path fill="#FF1616" opacity="1.000000" stroke="none" 
    d="
  M208.141296,394.289062 
    C208.012161,390.939270 207.607574,387.969330 209.292130,385.229492 
    C210.196899,383.757965 210.742111,382.447998 212.854492,383.905121 
    C214.387283,384.962402 215.755463,385.553894 214.005478,387.555328 
    C212.379730,389.414703 212.527344,391.422760 213.925003,393.411041 
    C215.185303,395.203888 217.060684,395.342743 218.832352,394.888275 
    C221.204498,394.279724 222.323257,392.475800 221.983643,390.000702 
    C221.627686,387.406464 223.190079,386.227448 225.285828,386.142059 
    C228.177109,386.024261 226.772003,388.500916 226.957474,389.843231 
    C227.826797,396.134918 223.790344,399.221893 219.344696,400.785095 
    C215.673508,402.075958 211.005646,399.349762 208.141296,394.289062 
  z"/>
  <path fill="#FF1616" opacity="1.000000" stroke="none" 
    d="
  M254.247940,232.265564 
    C252.205460,235.973831 249.582108,237.846909 245.798828,235.889130 
    C241.814941,233.827576 242.516296,230.233658 243.706299,226.840378 
    C244.189224,225.463348 244.463242,223.417130 247.043976,224.490143 
    C249.479843,225.502930 249.285690,226.801331 248.157562,228.692810 
    C247.846939,229.213608 247.065948,229.987671 247.809174,230.943726 
    C250.011520,230.291458 251.115295,228.298248 252.604950,226.851120 
    C254.741455,224.775604 257.114685,225.099625 259.282532,226.175980 
    C261.309631,227.182449 262.295563,229.355347 261.927338,231.606003 
    C261.565765,233.816299 260.758331,235.978561 259.940582,238.081818 
    C259.223846,239.925262 257.778900,240.720245 256.011749,239.420517 
    C254.525696,238.327560 252.997925,237.016052 255.331924,235.131424 
    C256.068329,234.536819 256.991486,233.896866 256.946686,232.745422 
    C256.848999,230.233810 255.730820,230.916672 254.247940,232.265564 
  z"/>
  <path fill="#38B6FF" opacity="1.000000" stroke="none" 
    d="
  M248.722305,292.660889 
    C247.056732,301.927826 247.056000,301.930634 238.212433,299.641693 
    C237.588348,299.480133 237.001602,299.144440 236.372375,299.037262 
    C227.759018,297.569916 227.593094,297.162231 229.918442,288.556061 
    C230.482162,286.469696 231.751968,287.130493 232.918030,287.000580 
    C234.262299,286.850769 234.883545,287.275909 235.103241,288.857880 
    C235.324127,290.448273 232.462234,293.063263 235.335251,293.323395 
    C238.291382,293.591034 236.084183,289.788269 237.607742,288.023132 
    C240.036041,288.888336 243.610977,288.792419 241.081406,293.273071 
    C240.793961,293.782227 241.022919,294.825348 241.791214,294.821014 
    C243.433578,294.811798 244.070694,293.618744 243.956039,292.144226 
    C243.812454,290.297363 244.710953,289.928375 246.322388,289.952728 
    C247.986008,289.977905 249.393799,290.025970 248.722305,292.660889 
  z"/>
  <path fill="#38B6FF" opacity="1.000000" stroke="none" 
    d="
  M255.765045,191.325119 
    C257.740082,192.723862 256.535400,193.854141 255.999649,194.960480 
    C255.042633,196.936768 254.012711,198.959381 256.255402,200.708740 
    C257.694092,201.830963 258.934204,203.724152 261.302795,202.314178 
    C263.576263,200.960861 265.306183,198.352875 264.612854,196.523422 
    C263.072235,192.458145 266.215790,194.427734 267.303345,193.918930 
    C270.426270,192.457870 268.172729,196.381912 269.797760,196.474670 
    C269.000549,200.629608 268.309601,204.858414 263.945221,207.015381 
    C260.419891,208.757690 256.899963,208.645935 253.802551,206.212021 
    C250.221985,203.398468 249.167755,199.432861 250.430481,195.202011 
    C251.075531,193.040726 251.140518,189.129562 255.765045,191.325119 
  z"/>
  <path fill="#F09114" opacity="1.000000" stroke="none" 
    d="
  M275.778198,168.641693 
    C275.395355,170.137711 275.253174,171.266617 275.038025,172.381424 
    C274.504456,175.146164 273.489594,176.770554 270.063843,175.697891 
    C266.215485,174.492889 262.205048,173.653931 258.202850,173.152283 
    C256.017700,172.878418 255.997986,171.899918 255.908295,170.266266 
    C255.786819,168.053421 256.999603,167.832642 258.697388,167.934326 
    C260.010834,168.013000 261.503082,168.684952 262.474976,168.557068 
    C265.604675,168.145233 270.620483,173.527771 271.717682,165.856461 
    C271.887146,164.671524 273.694641,164.803345 274.822632,165.028229 
    C276.868439,165.436081 275.755707,167.123459 275.778198,168.641693 
  z"/>
  <path fill="#652E8F" opacity="1.000000" stroke="none" 
    d="
  M334.604584,300.119843 
    C334.570953,301.405426 334.121368,302.201904 333.062927,300.357910 
    C333.451569,300.087006 333.872467,300.075226 334.604584,300.119843 
  z"/>
  <path fill="#4F2656" opacity="1.000000" stroke="none" 
    d="
  M320.773956,303.103973 
    C320.820587,303.533417 320.899292,304.712952 319.997986,303.331787 
    C320.132965,303.115753 320.316071,303.095581 320.773956,303.103973 
  z"/>
  <path fill="#62A856" opacity="1.000000" stroke="none" 
    d="
  M130.830353,220.327118 
    C130.269196,217.205276 126.005020,216.479828 127.007637,212.919998 
    C127.322456,211.802216 126.774246,209.673340 128.725693,210.267029 
    C130.257294,210.733002 131.419235,212.414062 132.745407,213.555298 
    C128.533035,218.122391 133.557739,220.571793 135.126663,223.875992 
    C134.176804,224.698349 132.574417,224.096298 131.832367,225.444931 
    C130.404526,224.005173 131.287155,222.228561 130.830353,220.327118 
  z"/>
  <path fill="#4F2656" opacity="1.000000" stroke="none" 
    d="
  M145.763916,247.410843 
    C145.335205,248.913025 144.581268,250.472321 142.917908,249.346863 
    C141.816040,248.601318 143.142059,247.830750 144.065369,247.077209 
    C144.773270,247.028183 145.194809,247.139862 145.763916,247.410843 
  z"/>
  <path fill="#62A856" opacity="1.000000" stroke="none" 
    d="
  M114.243607,185.501709 
    C114.303894,184.576172 114.516647,183.939194 115.349533,183.968506 
    C115.510170,183.974167 115.824867,184.507172 115.773674,184.728806 
    C115.604019,185.463364 115.550644,186.544464 114.243607,185.501709 
  z"/>
  <path fill="#62A856" opacity="1.000000" stroke="none" 
    d="
  M109.679771,190.976471 
    C108.917908,192.032242 107.752167,192.192322 106.240173,191.213913 
    C107.098000,189.813507 108.243484,190.746780 109.679771,190.976471 
  z"/>
  <path fill="#62A856" opacity="1.000000" stroke="none" 
    d="
  M116.679855,188.091522 
    C116.753021,188.807449 116.503120,189.611847 116.120178,190.705811 
    C115.987129,190.995361 116.001633,190.997696 115.999031,190.991547 
    C115.831482,191.198761 115.666534,191.412109 115.256149,191.303925 
    C115.010696,190.982376 115.000336,190.994888 115.009186,190.990677 
    C115.063255,189.859665 115.061462,188.710449 116.679855,188.091522 
  z"/>
  <path fill="#64B556" opacity="1.000000" stroke="none" 
    d="
  M109.993484,189.005920 
    C109.397217,188.853928 108.695541,188.826462 108.661232,187.979874 
    C108.656860,187.871948 108.936684,187.653000 108.943306,187.658417 
    C109.273422,187.928589 109.588051,188.217651 109.958084,188.745880 
    C110.011787,188.987106 109.999924,189.000000 109.993484,189.005920 
  z"/>
  <path fill="#64B556" opacity="1.000000" stroke="none" 
    d="
  M105.992844,185.992599 
    C106.150726,185.688309 106.314598,185.390259 106.478455,185.092209 
    C106.419800,185.319717 106.361145,185.547211 106.157021,185.890991 
    C106.011543,186.007263 105.998840,185.998840 105.992844,185.992599 
  z"/>
  <path fill="#62A856" opacity="1.000000" stroke="none" 
    d="
  M116.999214,187.998749 
    C117.201668,187.816162 117.393921,187.618958 117.616028,187.464783 
    C117.670036,187.427292 117.822227,187.531219 117.929260,187.570129 
    C117.744247,187.703476 117.559235,187.836823 117.188568,187.986603 
    C117.002914,188.003052 117.000000,188.000000 116.999214,187.998749 
  z"/>
  <path fill="#62A856" opacity="1.000000" stroke="none" 
    d="
  M131.489227,230.481079 
    C131.513199,230.470718 131.465256,230.491440 131.489227,230.481079 
  z"/>
  <path fill="#62A856" opacity="1.000000" stroke="none" 
    d="
  M127.481606,218.509552 
    C127.470795,218.484695 127.492416,218.534393 127.481606,218.509552 
  z"/>
  <path fill="#64B556" opacity="1.000000" stroke="none" 
    d="
  M361.719299,280.079163 
    C363.912994,279.584351 365.884491,278.698120 367.190186,281.206757 
    C367.802094,282.382477 368.847321,282.817230 370.091522,281.898651 
    C371.276855,281.023529 372.580109,280.759338 373.762146,281.729889 
    C375.370667,283.050598 374.128143,284.445190 373.640289,285.783752 
    C373.239441,286.883514 372.769440,287.968658 373.547058,289.072540 
    C374.967346,290.026947 375.481934,286.022339 377.057037,288.426514 
    C378.157013,290.105469 378.506195,291.949249 376.431763,293.179138 
    C373.875061,294.694977 374.720764,295.433502 376.614807,296.964447 
    C378.545929,298.525391 383.535767,300.523834 377.302612,303.316254 
    C376.577240,303.641205 376.797119,304.421326 377.296051,304.974396 
    C378.747589,306.583496 378.750519,306.580841 376.237915,307.788971 
    C373.757050,306.609222 371.522156,305.216034 368.974426,303.627838 
    C370.972290,302.830719 372.146820,301.800812 370.204651,299.882080 
    C368.682678,298.378540 367.605316,295.796722 365.858307,295.275055 
    C361.735382,294.043945 363.429565,288.866364 359.895416,287.590515 
    C361.770233,285.395996 361.399170,282.734100 361.719299,280.079163 
  z"/>
  <path fill="#652E8F" opacity="1.000000" stroke="none" 
    d="
  M352.375549,315.677338 
    C351.850311,313.918518 353.187866,313.106293 354.219666,312.029816 
    C355.079498,311.132782 355.722839,308.601959 356.131287,312.225647 
    C356.294617,313.674469 357.647766,313.080139 358.885681,313.116974 
    C357.778076,315.813690 354.833588,314.654053 352.375549,315.677338 
  z"/>
  <path fill="#64B556" opacity="1.000000" stroke="none" 
    d="
  M379.000854,317.999207 
    C376.316650,316.313599 373.985107,314.585846 378.642822,312.029846 
    C379.509521,312.669525 379.789185,313.428436 380.002869,314.643982 
    C380.182434,315.893188 380.010101,316.668518 379.257263,317.685486 
    C378.998260,318.002441 379.000000,318.000000 379.000854,317.999207 
  z"/>
  <path fill="#62A856" opacity="1.000000" stroke="none" 
    d="
  M386.895386,327.218536 
    C388.056793,329.222351 388.470245,331.019226 386.741821,332.873291 
    C388.858704,330.740570 382.762512,329.282776 386.895386,327.218536 
  z"/>
  <path fill="#652E8F" opacity="1.000000" stroke="none" 
    d="
  M364.274902,313.000885 
    C364.242432,312.111908 364.566162,311.240845 365.030853,310.046906 
    C366.196442,310.192657 367.221069,310.661255 368.663544,311.321014 
    C367.335419,312.594055 366.056549,312.957520 364.274902,313.000885 
  z"/>
  <path fill="#62A856" opacity="1.000000" stroke="none" 
    d="
  M379.225616,315.337372 
    C379.000763,314.256744 379.001495,313.506195 379.003540,312.380493 
    C379.004883,312.005341 379.502441,312.001801 379.751221,311.999695 
    C380.516235,312.867371 381.032471,313.737213 381.778320,314.804871 
    C381.719574,317.393097 380.582977,316.521057 379.225616,315.337372 
  z"/>
  <path fill="#62A856" opacity="1.000000" stroke="none" 
    d="
  M394.254578,325.990784 
    C394.204285,325.591949 394.057159,324.405457 394.962555,325.788239 
    C394.989014,325.988159 394.499390,325.992126 394.254578,325.990784 
  z"/>
  <path fill="#4F2656" opacity="1.000000" stroke="none" 
    d="
  M176.990982,211.006592 
    C176.982849,211.012833 177.326218,211.181046 177.326218,211.181046 
    C177.326218,211.181046 177.089478,211.171844 177.050293,211.078094 
    C177.011124,210.984360 176.999115,211.000351 176.990982,211.006592 
  z"/>
  <path fill="#4F2656" opacity="1.000000" stroke="none" 
    d="
  M322.516541,306.529938 
    C322.479492,306.548706 322.553558,306.511169 322.516541,306.529938 
  z"/>
  <path fill="#652E8F" opacity="1.000000" stroke="none" 
    d="
  M99.696014,223.513550 
    C99.438118,224.629135 99.184486,226.204483 97.796349,225.000931 
    C97.504814,224.748154 94.544655,224.190918 96.982506,222.295929 
    C97.934631,222.521378 98.725739,222.922195 99.696014,223.513550 
  z"/>
  <path fill="#652E8F" opacity="1.000000" stroke="none" 
    d="
  M132.706573,240.793549 
    C132.022156,241.598236 130.925049,243.133163 130.929749,240.252274 
    C131.517212,240.205475 132.018585,240.400101 132.706573,240.793549 
  z"/>
  <path fill="#64B556" opacity="1.000000" stroke="none" 
    d="
  M102.760788,199.049179 
    C102.865417,199.613281 102.820129,200.310745 101.974800,200.337891 
    C101.866112,200.341400 101.646820,200.062225 101.653900,200.053314 
    C101.920769,199.717911 102.208191,199.398849 102.760788,199.049179 
  z"/>
  <path fill="#64B556" opacity="1.000000" stroke="none" 
    d="
  M102.988678,198.983231 
    C102.977150,198.968857 103.190735,198.685226 103.190735,198.685226 
    C103.190735,198.685226 103.189575,198.923691 103.109146,198.972229 
    C103.028709,199.020752 103.000198,198.997604 102.988678,198.983231 
  z"/>
  <path fill="#64B556" opacity="1.000000" stroke="none" 
    d="
  M398.987854,317.986511 
    C399.144562,317.389160 399.181000,316.688904 400.021393,316.660278 
    C400.129547,316.656586 400.348053,316.933472 400.341309,316.941986 
    C400.076080,317.275604 399.790710,317.593140 399.269135,317.970306 
    C399.030304,318.027832 399.001465,318.000763 398.987854,317.986511 
  z"/>
  <path fill="#64B556" opacity="1.000000" stroke="none" 
    d="
  M394.000610,321.999390 
    C394.001221,321.998779 393.706024,321.820709 393.706024,321.820709 
    C393.706024,321.820709 393.918243,321.835968 393.958496,321.918579 
    C393.998779,322.001221 394.000000,322.000000 394.000610,321.999390 
  z"/>
  <path fill="#652E8F" opacity="1.000000" stroke="none" 
    d="
  M130.483017,244.474243 
    C130.517166,244.457504 130.448868,244.490982 130.483017,244.474243 
  z"/>
  <path fill="#67C357" opacity="1.000000" stroke="none" 
    d="
  M115.035156,191.186096 
    C115.010696,190.982376 115.503586,190.983780 115.750008,190.984589 
    C115.796341,191.380051 115.950439,192.595123 115.035156,191.186096 
  z"/>
  <path fill="#67C357" opacity="1.000000" stroke="none" 
    d="
  M122.000275,195.000305 
    C122.000549,195.000610 121.824532,195.289612 121.824532,195.289612 
    C121.824532,195.289612 121.838463,195.081009 121.918930,195.040192 
    C121.999390,194.999390 122.000000,195.000000 122.000275,195.000305 
  z"/>
  <path fill="#652E8F" opacity="1.000000" stroke="none" 
    d="
  M332.491028,305.718262 
    C332.971161,305.445068 333.398499,305.378052 333.825836,305.311066 
    C333.766205,305.548553 333.727234,305.981415 333.643463,305.990295 
    C333.248413,306.031982 332.841156,305.957489 332.491028,305.718262 
  z"/>
  <path fill="#652E8F" opacity="1.000000" stroke="none" 
    d="
  M325.015198,306.015564 
    C325.123260,305.396912 325.221832,304.768799 325.320435,304.140656 
    C325.462463,304.197723 325.732849,304.281525 325.727356,304.307953 
    C325.632660,304.763458 325.503906,305.211884 325.183502,305.827545 
    C324.984375,305.993256 325.005737,306.006073 325.015198,306.015564 
  z"/>
  <path fill="#62A856" opacity="1.000000" stroke="none" 
    d="
  M405.027954,321.800842 
    C405.978180,322.424377 406.007568,323.165009 405.557190,323.875916 
    C405.258270,324.347839 404.718506,324.333435 404.438568,323.850281 
    C404.019897,323.127747 404.040100,322.385956 405.027954,321.800842 
  z"/>
  <path fill="#67C357" opacity="1.000000" stroke="none" 
    d="
  M379.001129,315.381592 
    C379.844360,315.308746 380.637299,315.971466 381.808441,315.190674 
    C383.236084,317.620728 380.776276,317.224030 379.316711,317.953857 
    C378.999603,317.253571 379.000916,316.504730 379.001129,315.381592 
  z"/>
  <path fill="#67C357" opacity="1.000000" stroke="none" 
    d="
  M380.000763,322.001221 
    C380.001526,322.002441 380.173309,321.712341 380.173309,321.712341 
    C380.173309,321.712341 380.158234,321.917511 380.077881,321.958008 
    C379.997559,321.998505 380.000000,322.000000 380.000763,322.001221 
  z"/>
  <path fill="#652E8F" opacity="1.000000" stroke="none" 
    d="
  M167.183136,211.202988 
    C167.767487,210.956848 168.523178,210.929016 169.278854,210.901184 
    C169.223328,211.144974 169.160126,211.601898 169.113419,211.600204 
    C168.525711,211.579071 167.940384,211.491699 167.183136,211.202988 
  z"/>
  <path fill="#652E8F" opacity="1.000000" stroke="none" 
    d="
  M164.517212,212.468948 
    C164.532867,212.502197 164.501541,212.435699 164.517212,212.468948 
  z"/>
  <path fill="#652E8F" opacity="1.000000" stroke="none" 
    d="
  M166.993835,211.004440 
    C166.987106,211.009720 166.636627,210.771194 166.636627,210.771194 
    C166.636627,210.771194 166.928162,210.776031 166.969986,210.880356 
    C167.011810,210.984680 167.000565,210.999161 166.993835,211.004440 
  z"/>
  <path fill="#000000" opacity="1.000000" stroke="none" 
    d="
  M228.786560,360.498962 
    C227.445786,361.939178 226.477600,363.748993 224.488846,362.751556 
    C222.336502,361.672058 219.740631,360.823273 220.010498,357.574585 
    C220.225998,354.980194 222.078354,355.023041 223.993515,354.984833 
    C227.556824,354.913696 229.239761,356.553436 228.786560,360.498962 
  z"/>
  <path fill="#000000" opacity="1.000000" stroke="none" 
    d="
  M234.706116,326.957153 
    C231.942230,326.574036 229.592957,326.159363 227.033493,325.707611 
    C227.877792,322.006500 230.493790,321.314575 233.266724,322.281616 
    C234.852936,322.834778 237.348465,324.263214 234.706116,326.957153 
  z"/>
  </svg>
)

export const PwnyBanner = (style: React.CSSProperties) => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 498.6 128.13">
    <defs>
      <style>
        {".cls-1{fill: #66bf57;}.cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-22 {stroke-width: 0px;}.cls-2{fill: #ff1313;}.cls-3 {fill: #5e307d;}.cls-4 {fill: #35b2ff;}.cls-5 {fill: #ff904c;}.cls-6 {fill: #512a5c;}.cls-7 {fill: #65338c;}.cls-9 {fill: #008134;}.cls-10 {fill: #61a256;}.cls-23 {fill: #3c5;font-family: HelveticaNeue-CondensedBold, 'Helvetica Neue';font-size: 75px;font-weight: 700;}.cls-11 {fill: #5de3e7;}.cls-12 {fill: #ff65c3;}.cls-13 {fill: #66bd58;}.cls-14 {fill: #66bc58;}.cls-15 {fill: #f19314;}.cls-16 {fill: #60a156;}.cls-17 {fill: #65328d;}.cls-18 {fill: #5271ff;}.cls-19 {fill: #ffbb01;}.cls-20 {fill: #65338d; }.cls-21 {fill: #62b057;}.cls-22 {fill: #563068;}"}
      </style>
    </defs>
    <path className="cls-8" d="m56.06,128.06c-18,0-35.5-.06-53,.07C.56,128.15,0,127.56,0,125.06c.09-40.67.09-81.33,0-122C0,.56.56,0,3.06,0c40.67.09,81.33.09,122,0,2.5,0,3.07.56,3.06,3.06-.09,40.67-.09,81.33,0,122,0,2.5-.56,3.08-3.06,3.07-22.83-.11-45.67-.07-69-.07Z"/>
    <path className="cls-1" d="m98.48,87.74c-8.96,2.09-17.51,4.02-26.29,5.67-2.41-8.19-1.78-12.67,12.96-13.41,2.5-1.19,4.87-.65,7.47-1.26,2.88.41,5.3,1.04,6.92,3.06,1.62,2.02,2.92,4.24-1.05,5.94Z"/>
    <path className="cls-1" d="m29.64,44.38c8.78-2.89,17.41-4.51,26.29-5.69,1.76,9.09-.18,11.73-9,12.77-1.64.19-3.32.16-5.3.94-2.39.97-4.44-.19-6.87.35-3.12-.08-5.04-1.58-6.44-3.63-1.12-1.64-1.98-3.59,1.33-4.75Z"/>
    <path className="cls-14" d="m84.27,76.26c-3.03-1.96-5.87-3.54-9.07-5.19-.7-1.19-.96-2.32-1.05-3.47-.49-5.92,2.52-8.13,7.8-5.54,3.84,1.88,7.83,3.57,11.13,7.06.15,3.39,3.71,6.1.55,9.54-3.76,1.27-6.54-.07-9.36-2.4Z"/>
    <path className="cls-3" d="m92.36,70.13c-4.08-2.48-8.01-4.56-11.83-6.84-3.23-1.93-4.39-.83-4.6,2.57-.09,1.46-.64,2.89-1.04,4.73-.96-.92-2.43-2.15-2.67-3.58-.6-3.58-1.11-7.86,1.5-10.35,1.47-1.4,4.64,1.51,6.91,2.66,7.48,3.8,14.79,7.92,22.24,11.77,1.95,1.01,2.79,2.71,3.88,4.71-3.9,3-6.55-.34-9.96-1.91-2.22-.57-3.3-1.93-4.44-3.77Z"/>
    <path className="cls-13" d="m43.88,55.86c3.3,2.06,6.44,3.74,9.8,5.66-.11,3.21,1.67,6.84-1.62,8.91-3.52,2.21-6.18-.91-8.99-2.33-2.35-1.19-4.8-2.23-7.3-4.01-.47-.51-.62-.75-.91-1.38-.22-3.39-3.25-5.9-1.35-9.53,4.14-1.61,7.21.19,10.37,2.68Z"/>
    <path className="cls-7" d="m35.82,62.92c4.05,1.78,7.88,3.41,11.44,5.49,3.15,1.85,4.78,1.79,4.95-2.35.05-1.26.75-2.5,1.43-4.03.68,1.31,2.49,2.09,2.48,4.04-.02,3.32.7,7.36-1.74,9.39-1.62,1.35-4.63-1.65-6.95-2.77-4.44-2.14-8.7-4.68-13.24-7.33-.19-1.33.39-1.99,1.65-2.44Z"/>
    <path className="cls-17" d="m86.36,80.78c-1.29.7-2.39,1.27-3.51,1.32-6.63.28-10.97,2.82-10.74,10.65-4.59-3.14-3.9-8.25-2.38-12.2,1.54-4,6.7-2.85,10.83-3.56,2.76.33,5.64-.16,5.8,3.79Z"/>
    <path className="cls-20" d="m40.86,51.33c2.66-.98,5.17-1.88,7.75-2.18,4.32-.51,7.18-2.03,6.67-7.02-.08-.77.35-1.59.7-2.75,6.22,6.26,4.26,12.82-4.24,14.97-1.63.41-3.19.69-5.25.74-2.43-.76-4.97-.71-5.64-3.75Z"/>
    <path className="cls-21" d="m92.15,70.22c1.46.26,2.89.94,4.6,1.81,2.08,4.49,6.11,6.26,9.92,8.8,1.35,6.96-4.15,6.01-8.19,7.13,2.32-2.11,1.58-3.94-.85-5.48-1.24-.79-2.68-1.28-4.28-2.13-.6-.54-.8-.92-.79-1.7,2.51-3.06-1.43-5.37-.4-8.42Z"/>
    <path className="cls-21" d="m34.47,52.45c-.87,3.35,1.92,5.79,1.56,9.28-2.55,1.95-4.28.63-6.02-1.63-.67-3.48-2.22-5.83-5.21-7.07-2.45-1.02-3.48-2.83-2.98-5.76,1.75-3.43,4.82-2.72,7.83-3.11-3.24,5.29,2.58,5.14,4.83,7.37.32.34.26.52,0,.92Z"/>
    <path className="cls-16" d="m21.68,47.2c.38,1.91,1.03,3.48,3.53,3.86,4.28.64,6.04,3.35,5.73,7.96-4.26,2.99-6.3-1.23-9.49-2.9-.18-3.11-.14-5.89.23-8.91Z"/>
    <path className="cls-10" d="m106.96,80.67c-7.23.1-9.58-1.82-9.93-8.04,3.02.9,6.05,2.2,9.44,3.5.43,1.39.5,2.76.5,4.54Z"/>
    <path className="cls-6" d="m21.27,56.13c3.31-.15,5.8,2.29,9.27,3.22,1.85.95,3.34,1.82,5.16,2.74.33.05.4.43.43.62-.51.82-1.06,1.46-1.89,2.24-5.17-1.72-10.3-3.24-12.97-8.81Z"/>
    <path className="cls-22" d="m34.59,52.8c-.19-.37-.07-.59.16-.97,1.88-.39,3.66-.63,5.83-.86,1.73,1.59,4.46,1.21,5.41,3.8-.4.65-.88.97-1.65,1.38-3.73.1-6.5-1.76-9.74-3.35Z"/>
    <path className="cls-18" d="m54.31,93.56c-.6-2.68-.27-4.7,2.36-5.38,1.99-.52,2.79.78,3.71,2.4.9,1.59.41,2.37-.67,3.35-1.77,1.62-3.48,1.68-5.4-.37Z"/>
    <path className="cls-22" d="m92.29,78.24c.48.47.62.87.78,1.57-2.03.66-4.07,1-6.51,1.34-.94-2.46-3.67-2.3-5.28-4.02.26-1.16,1.25-1,2.47-1.19,3.23-.25,5.65,1.2,8.54,2.29Z"/>
    <path className="cls-9" d="m72.87,36.08c-1.38,2.56-3.47,2.02-5.39,1.81-1.3-.14-1.33-1.27-1.35-2.26-.03-1.38.22-3.04,1.67-3.23,2.41-.32,4.55.46,5.07,3.68Z"/>
    <path className="cls-4" d="m64.61,48.09c1.18.06,1.38.69,1.63,1.29,1.9-1.33,3.84-1.88,3.71,1.35-.08,2.05-1.75,3.45-4.06,3.31-3.16-.18-2.85-2.53-2.79-4.68.02-.74.35-1.21,1.52-1.27Z"/>
    <path className="cls-12" d="m59.95,65.71c2.33-.33,4.89-2.15,6.04,1.02.34.93.6,2.78-.63,3.12-1.52.43-3.41.38-4.88-.16-1.56-.57-1.97-2.12-.52-3.99Z"/>
    <path className="cls-2" d="m57.44,98.11c3.26,1.39,1.04,3.18.36,4.29-.81,1.31-2.7.81-4.06.44-1.47-.4-1.69-1.74-1.68-3.16.01-1.14.58-2.02,1.44-2.37,1.39-.57,1.19.87,1.71,1.57,1.02,1.38.92-1.2,2.24-.77Z"/>
    <path className="cls-15" d="m62.06,84.06c-1.11,3.54-2.83,1.7-4.58,1.14-1.92-.61-1.48-2-1.29-3.16.25-1.51,1.46-2,2.94-1.97,2.42.04,3.05,1.48,2.93,3.99Z"/>
    <path className="cls-11" d="m59.95,72.48c1.38,1.27,3.98-.65,4.04,2.11.02,1.13.87,3.05-.88,3.3-2,.27-4.4-.36-5.5-2.07-.96-1.48-.05-3.19,2.34-3.34Z"/>
    <path className="cls-2" d="m61.08,59.15c.19-2.01.73-3.53,2.7-2.66,1.4.62,3.58.2,4.06,2.19.19.77-.29,2.18-.92,2.59-2.45,1.58-4.28.19-5.85-2.11Z"/>
    <path className="cls-5" d="m71.2,30.02c-1.95-.87-3.95-1.47-3.03-3.88.74-1.95,2.54-.87,3.85-1.03,1.69-.21,2.98.59,2.98,2.42,0,2.17-1.53,2.66-3.8,2.5Z"/>
    <path className="cls-19" d="m69.27,41.1c3.09,1.16,2,4.28,1.21,4.59-2.03.8-4.26-.42-5.96-1.88-.76-.65-.39-1.56.69-1.65,1.3-.11,2.57-.19,4.06-1.06Z"/>
    <text className="cls-23" transform="translate(136.36 88.07)"><tspan x="0" y="0">SISC Coders</tspan></text>
  </svg>)

const social_icons = new Map([
  ["ctftime", {display: "CTFtime", svg: <CTFtimeSvg />}],
  ["discord", {display: "Discord", svg: <DiscordSvg />}],
  ["email", {display: "Email", svg: <MailSvg />}],
  ["github", {display: "GitHub", svg: <GitHubSvg />}],
  ["instagram", {display: "Instagram", svg: <InstagramSvg />}],
  ["linkedin", {display: "LinkedIn", svg: <LinkedInSvg />}],
  ["mastodon", {display: "Mastodon", svg: <MastodonSvg />}],
  ["reddit", {display: "Reddit", svg: <RedditSvg />}],
  ["twitter", {display: "Twitter", svg: <TwitterSvg />}],
  ["website", {display: "Website", svg: <GlobeSvg />}],
  ["youtube", {display: "YouTube", svg: <YouTubeSvg />}],
])

export const getSocialIcon = (name: string) => {
  return social_icons.get(name.toLowerCase())
}
